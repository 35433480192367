.base {
  $white: var(--white);
  $black: var(--black);
  @mixin transition(
    $property: all,
    $duration: 0.45s,
    $ease: cubic-bezier(0.65, 0, 0.076, 1)
  ) {
    transition: $property $duration $ease;
  }

  * {
    box-sizing: border-box;

    &::before,
    &::after {
      box-sizing: border-box;
    }
  }

  position: relative;
  display: inline-block;
  cursor: pointer;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: 1rem;
  font-family: inherit;
  width: 12rem;
  height: auto;

  .circle {
    @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
    position: relative;
    display: block;
    margin: 0;
    width: 3rem;
    height: 3rem;
    background: $black;
    border-radius: 1.625rem;

    .icon {
      @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      background: $white;

      &.arrow {
        @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
        left: 0.625rem;
        width: 1.125rem;
        height: 0.125rem;
        background: none;

        &::before {
          position: absolute;
          content: '';
          top: -0.25rem;
          right: 0.0625rem;
          width: 0.625rem;
          height: 0.625rem;
          border-top: 0.125rem solid $white;
          border-right: 0.125rem solid $white;
          transform: rotate(45deg);
        }
      }
    }
  }

  .buttonText {
    @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.75rem 0;
    margin: 0 0 0 1.85rem;
    color: $black;
    font-weight: 700;
    line-height: 1.6;
    text-align: center;
    text-transform: uppercase;
  }

  &:hover {
    .circle {
      width: 100%;

      .icon {
        &.arrow {
          background: $white;
          transform: translate(1rem, 0);
        }
      }
    }

    .buttonText {
      color: $white;
    }
  }

  @media (hover: none) {
    & {
      .circle {
        width: 100%;

        .icon {
          &.arrow {
            background: $white;
            transform: translate(1rem, 0);
          }
        }
      }

      .buttonText {
        color: $white;
      }
    }
  }
}
