.spinner {
  margin: 0 1.25rem;
}

.successMessage {
  position: absolute;
  padding-top: 0.5rem;
  color: var(--green-600);
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.nameInput,
.emailInput,
.messageInput {
  transition: all 1s;
}

.recaptchaInfo {
  margin-top: 0.5rem;
}
