.base {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #00000044;
}

.heading {
  text-transform: uppercase;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0.1em;
  margin-bottom: 0.75rem;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.75rem;
  line-height: 1.625;
}

.skillItem {
  text-transform: uppercase;
}
