.base {
  margin-bottom: 2.5rem;
}

.formContainer {
  width: 100%;
  max-width: var(--screen-sm);
  margin: 0 auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  margin-bottom: 6rem;
}
