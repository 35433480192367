.base {
  display: inline;
  animation: spin 1s linear infinite;
  height: 1.25rem;
  width: 1.25rem;
  color: var(--color-white);
}

.circle {
  opacity: 0.25;
}

.arc {
  opacity: 0.75;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
