.base {
  position: relative;
  background-color: var(--color-indigo-900);
  color: var(--white);
  overflow-x: hidden;
  padding-top: 4rem;
  padding-bottom: 10vh;
  clip-path: polygon(0 10vh, 100% 0%, 100% calc(100% - 10vh), 0% 100%);
  background-size: cover;
}

.contained {
  padding: 0 1.25rem 1.25rem;
  margin: 0 auto;
  max-width: var(--screen-lg);
  width: 100%;
}

.missionSection {
  line-height: 1.625;
  margin-bottom: 4rem;

  p {
    margin-bottom: 1.25rem;
  }
}

.skillCards {
  display: grid;
  gap: 2rem;
  max-width: var(--screen-md);
  margin: 0 auto 2rem;
  opacity: 1;

  // 640px is --screen-sm
  @media (min-width: 640px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.mainContent,
.frontEndContainer,
.backEndContainer {
  transition: all 1s;
}
