.base {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8rem;

  // --screen-md is 768px
  @media (min-width: 768px) {
    flex-direction: row;

    &:nth-child(odd) {
      flex-direction: row-reverse;
    }
  }
}

.screenshotHolder {
  height: 100%;
  width: 100%;
  flex: 1 1 0px;
  transition: all 1s;
}

.screenshot {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 1rem;
}

.spacer {
  width: 3.5rem;
  height: 1.25rem;
}

.infoText {
  flex: 1 1 0px;
  transition: all 1s;

  * {
    margin-top: 0;
    margin-bottom: 1.25rem;
  }
}

.titleLink {
  text-decoration: none;
}

.title {
  line-height: 2.5rem;
  font-weight: 700;
  color: var(--color-dark);
  font-size: 2.25rem;
  margin-bottom: 0.25rem;
}

.subtitle {
  font-weight: 700;
  margin-top: 0;
}

.features {
  margin-bottom: 0.5rem;
}

.technologies {
  margin-bottom: 1.7rem;
}
