.base {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;

  overflow-x: hidden;

  // Add a gradient background for when the depth section is loading.
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1), var(--violet-900));

  // The hero section should be at least as tall as the screen or 550px,
  // which is the least height that can still accommodate The content.
  min-height: max(100vh, 550px);
  height: 100vh;
}

.container {
  margin-left: auto;
  margin-right: auto;
  color: var(--white);
  text-shadow: 0 0 15px #ffffff55;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  * {
    margin-top: 0;
  }
}

.headingSmall {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 0.5rem;
}

.headingLarge {
  font-size: 3rem;
  line-height: 1;
  font-weight: 900;
  text-transform: uppercase;
  font-family: metropolis, sans-serif;
  margin-bottom: 1.25rem;
}

.ctaBtn {
  margin-right: 1.25rem;
  margin-bottom: 0.5rem;
}

// var(--screen-xs) is defined as 475px in ck.css.
@media (min-width: 475px) {
  .container {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    align-items: flex-start;
  }

  .headingSmall {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .headingLarge {
    font-size: 3.75rem;
    line-height: 1;
  }
}

// var(--screen-sm) is defined as 640px in ck.css.
@media (min-width: 640px) {
  .headingSmall {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .headingLarge {
    font-size: 6rem;
    line-height: 1;
  }
}
